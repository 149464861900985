<template>
  <div class="container min-width-card p-0 m-0">
    <loading :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frmModeration')" data-vv-scope="frmModeration" novalidate="novalidate">
      <div class="row full-heigth w-100 p-0 m-0">
        <div class="card card-fullheight w-100 m-0 pb-2">
          <div class="card-body">
            <button type="button" class="royalc-btn-close pt-2 pr-0 float-right" @click="onClose()"
              aria-label="Close modal"><i class="fas fa-times"></i></button>
            <h5 class="box-title my-0 pb-0 pt-2"><small>Moderação de {{ typeName }}</small></h5>
            <hr>

            <!-- Bloco superior -->
            <div class="row px-0 mx-0">

              <!-- Motivo e Referência -->
              <div class="col-md-5 px-0 mx-0 mb-0">
                  <!--  Motivo  -->
                <div class="row">
                  <div class="col-12 text-left form-group px-2">
                    <label class="small grey-label mb-0">Motivo</label>
                    <v-select class="grey-field" name="valuation.motivo" label="nome" :clearable="false"
                      :searchable="true" v-model="valuation.motivo" :options="reasonList"
                      @search="reasonFetchOptions" v-validate="{ required: true }">
                      <template slot="no-options" slot-scope="{searching, search}">
                        <em v-if="!searching" style="opacity: 0.5;">
                          Digite para buscar um motivo
                        </em>
                        <em v-else style="opacity: 0.5;">
                          Nenhum motivo encontrado para {{ search }}
                        </em>
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.nome }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.nome }}
                        </div>
                      </template>
                    </v-select>
                    <transition name="slide" mode="">
                      <small v-if="errors.first('valuation.motivo')" class="royalc-error-field">{{ errors.first('valuation.motivo') }}</small>
                    </transition>
                  </div>

                </div>

                <!--  Referencia  -->
                <div class="row">
                  <div class="col-12 text-left form-group px-2 ">
                    <label class="small grey-label mb-0">Referência interna</label>
                    <input type="text" class="md-form-control text-left mb-0"
                      v-model="valuation.referencia_interna"
                      :error="submitted ? errors.first('valuation.referencia_interna') : ''">
                  </div>
                </div>
              </div>

              <!-- Texto -->
              <div class="col-md-7 text-left form-group h-100 px-2 mb-0">
                <label class="small grey-label mb-0">Descricao</label>
                <custom-textarea
                  v-model="valuation.texto"
                  name="valuation.texto"
                  type="text"
                  rootClassName="md-form my-0"
                  inputClassName="md-form-control my-0"
                  :rows="12"
                  :error="errors.first('valuation.texto')">
                </custom-textarea>
              </div>
            </div>

            <hr>

            <div class="row w-100">

              <!-- Pessoas -->
              <div class="col-md-7 text-left pl-0 mb-0">
                <label class="small grey-label mb-0">Pessoas</label>
                <custom-advanced-multiple-select
                  id-field="id"
                  labelField="nome"
                  name="valuation.alvos"
                  class="mx-0"
                  :enableStatus="1"
                  :no-options="'Buscar Funcionário'"
                  :tableFields="employeeFields"
                  :value="valuation.alvos"
                  :options="employeeList"
                  v-bind:fetchOptions="employeeFetchOptions"
                  @onRemoved="employeeRemoved"
                  @onInput="employeeSelected"
                  @rowClicked="employeeSelected"
                  @toggleStatus="toggleEmployeeStatus"
                  v-validate="{ required: true }">
                </custom-advanced-multiple-select>
                <transition name="slide" mode="">
                  <small v-if="errors.first('valuation.alvos')"
                    class="royalc-error-field">{{ errors.first('valuation.alvos') }}</small>
                </transition>
              </div>

              <!-- Quadro direita -->
              <div class="col-md-5 px-0 mx-0 mb-0">
                <div class="row">
                  <!--  Origem  -->
                  <div class="col-12 form-group text-left px-2">
                    <label class="small grey-label mb-1">Origem</label>
                    <v-select class="grey-field" name="valuation.origem" label="nome" :clearable="false"
                      :searchable="true" v-model="valuation.origem" :options="originList"
                      @search="originFetchOptions" v-validate="{ required: true }">
                      <template slot="no-options" slot-scope="{searching, search}">
                        <em v-if="!searching" style="opacity: 0.5;">
                          Digite para buscar uma pessoa
                        </em>
                        <em v-else style="opacity: 0.5;">
                          Nenhuma pessoa encontrada para {{ search }}
                        </em>
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.nome }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.nome }}
                        </div>
                      </template>
                    </v-select>
                    <transition name="slide" mode="">
                      <small v-if="errors.first('valuation.origem')" class="royalc-error-field">{{ errors.first('valuation.origem') }}</small>
                    </transition>
                  </div>
                </div>

                <div class="row w-100">
                  <!-- Informante -->
                  <div class="col-12 form-group text-left mb-1">
                    <label class="small grey-label mb-0">Informado por</label>
                    <div class="pt-1 grey-label">
                    {{ valuation.informante ? valuation.informante.nome : '-' }}
                    </div>
                  </div>
                </div>

                <hr>

                <!-- Peso -->
                <div class="row">
                  <div class="col-md-6 text-left">
                    <label class="small grey-label mb-0">Peso sugerido</label>
                    <input type="text" class="md-form-control text-center mb-0"
                      :value="valuation.motivo ? valuation.motivo.peso_padrao : '-'" :disabled="true">
                  </div>

                  <div class="col-md-6 text-left">
                    <label class="small grey-label mb-0">Peso aplicado</label>
                    <input name="valuation.peso" type="number" min="0" step="0.1" class="md-form-control text-center mb-0"
                      v-model="valuation.peso" v-validate="{ required: true }"
                      :error="submitted ? errors.first('valuation.peso') : ''">
                    <transition name="slide" mode="">
                      <small v-if="errors.first('valuation.peso')"
                        class="royalc-error-field">{{ errors.first('valuation.peso') }}</small>
                    </transition>
                  </div>
                </div>

                <!-- Positivo e Valor -->
                <div class="row mt-3">
                  <div class="col-md-6 text-left py-2">
                    <small class="text-primary" v-if="!valuation.motivo">-</small>
                    <small class="text-primary" v-else-if="valuation.motivo.tipo.positivo">* Pontos positivos</small>
                    <small class="text-danger" v-else>* Pontos negativos</small>
                  </div>

                  <!--  Valor  -->
                  <div class="col-md-6 form-group text-left">
                    <label v-if="!valuation.motivo " class="small grey-label mb-0">Receita/Prejuízo</label>
                    <label v-else-if="valuation.motivo.tipo.positivo" class="small grey-label mb-0">Receita</label>
                    <label v-else class="small grey-label mb-0">Prejuízo</label>
                    <input type="number" min="0" step=".01" class="md-form-control text-center"
                      v-model="valuation.valor"
                      :error="submitted ? errors.first('valuation.valor') : ''">
                  </div>

                </div>

                <hr>

                <div class="row custom-row mx-0">
                  <div v-if="valuation.arquivos && valuation.arquivos.length"
                    class="col-12 form-group text-left">
                    <small class="text-left">Anexos</small>
                    <div v-for="(anexo, index) in valuation.arquivos" v-bind:key="index" class="font-weight-bold pl-2">
                      <button type="button" class="btn btn-link btn-sm my-0"
                        @click="onDownload(anexo.file)">{{ anexo.file.original_name }}</button>
                      <button type="button" class="royalc-btn-close pt-3 px-0 mx0" @click="onDeleteFile(anexo.file)"
                        aria-label="Close modal"><i class="fas fa-times"></i></button>
                    </div>
                  </div>
                  <div v-else class="col-12 px-0 mb-3">
                    <small class="font-italic">Sem anexos</small>
                  </div>
                </div>

                <div class="row">
                  <!--  Attach -->
                  <div class="col-md-12 form-group px-2">
                    <custom-file-upload
                      v-model="newFiles"
                      v-show="!isLoading"
                      name="attach"
                      v-validate="{ required: false }"
                      :max-files="5"
                      :error="errors.first('newFiles')">
                    </custom-file-upload>
                  </div>
                </div>

              </div>
            </div>

            <hr>

            <!-- Observações -->
            <div class="row">
              <div class="col-12 text-left form-group h-100 px-2 mb-0">
                <label class="small grey-label mb-0">Observações</label>
                <custom-textarea
                  v-model="valuation.observacao"
                  name="valuation.observacao"
                  type="text"
                  rootClassName="md-form my-0"
                  inputClassName="md-form-control my-0"
                  :rows="6"
                  v-validate="{ required: false }"
                  :error="errors.first('valuation.observacao')">
                </custom-textarea>
              </div>
            </div>

            <hr>

            <!-- Botões abaixo -->
            <div class="row p-0 m-0">
              <div class="col-md-4 text-left p-0 m-0">
                  <!-- <a class="btn btn-sm btn-outline-light m-1"
                    v-bind:href="$util.mountLink('ValuationEdit', this.valuation)">Editar</a> -->
              </div>

              <div class="col-md-4 align-center">
                  <button :disabled="isLoading" class="btn btn-sm btn-outline-light btn-a-color m-1" @click="onRefuse()">
                    <span >Recusar</span>
                  </button>

                  <button :disabled="isLoading" class="btn btn-sm btn-outline-light btn-a-color m-1" @click="onAllow()">
                    <span >Aprovar</span>
                  </button>
              </div>

              <div class="col-md-4 text-right p-0 m-0">
                  <button class="btn btn-sm btn-outline-light btn-a-color m-1" @click="onClose()">
                    <span >Fechar</span>
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Form
import CustomFileUpload from '@/components/Forms/CustomFileUpload.vue'
import CustomAdvancedMultipleSelect from '@/components/Forms/CustomAdvancedMultipleSelect.vue'
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'
// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import ValuationService from '@/services/ValuationService'
import ValuationReasonService from '@/services/ValuationReasonService'
import PersonService from '@/services/PersonService'
// import TeamService from '@/services/TeamService'
import EmployeeService from '@/services/EmployeeService'

export default {
  name: 'ValuationModeration',
  components: {
    Loading,
    vSelect,
    CustomFileUpload,
    CustomAdvancedMultipleSelect,
    CustomTextarea
  },
  data () {
    return {
      isLoading: false,
      formErrors: {},
      fullPage: true,
      submitted: false,
      reasonList: [],
      causeList: [],
      originList: [],
      employeeList: [],
      employeeFields: [
        {
          name: 'id',
          title: '#',
          sortField: 'id',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: 'Nome',
          sortField: 'nome',
          formatter: (value) => this.util.formatEmpty(value)
        },
        {
          name: 'equipe',
          title: 'Equipe',
          sortField: 'equipe',
          selectable: true,
          formatter: (value) => this.util.formatEmpty(value)
        },
        {
          name: 'protagonista',
          title: 'Protagonista',
          sortField: 'protagonista',
          toggleable: true,
          formatter: (value) => this.util.formatEmpty(value)
        }
      ],
      valuation: {
        motivo: null,
        origem: null,
        descricao: null,
        observacao: null,
        referencia_interna: null,
        valor: null,
        peso: null,
        situacao: 0,
        dataCriacao: null,
        arquivos: [],
        alvos: []
      },
      newFiles: [],
      deletedFiles: [],
      delay: 400
    }
  },
  created () {
    let _this = this

    _this.isLoading = true

    this.loadData()

    let filters = {
      'searchTerm': '',
      'per_page': 500,
      'includeAlan': false
    }

    ValuationReasonService.getReasonOptions(filters).then(res => {
      this.reasonList = res.data.data
    })

    PersonService.getPersons(filters).then(res => {
      _this.originList = res.data.data
    })

    _this.isLoading = false
  },
  beforeMount () {
  },
  mounted () {
  },
  methods: {
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    // mountLink (itemName, object) {
    //   return this.$router.resolve({ name: itemName, params: { id_Valuation: object.id } }).href
    // },
    onfocus () {
    },
    onClose () {
      global.instanceApp.$alertSwal.close()
    },
    isset (value) {
      return value !== null && value !== undefined && value !== ''
    },
    loadData () {
      return new Promise((resolve, reject) => {
        ValuationService.get(this.id).then(res => {
          let valuation = res.data.data

          let formatedTargets = valuation.alvos.map((target) => {
            let teamsOptions = target.alvo.equipes.map(x => {
              return {
                id: x.equipe.id,
                nome: x.equipe.nome
              }
            })

            let selectedTeam = null
            if (target.equipe) {
              selectedTeam = {
                id: target.equipe.id,
                nome: target.equipe.nome
              }
            }

            return {
              id: target.alvo.id,
              nome: target.alvo.id_pessoa.nome,
              protagonista: target.tipo_participacao,
              equipe: {
                selected: selectedTeam,
                options: teamsOptions
              },
              status: 1
            }
          })
          valuation.alvos = formatedTargets
          this.valuation = valuation
        }).catch((err) => {
          // console.log('erro loadData', err)
          reject(err)
        })
      })
    },
    /**
     * Lista de motivos
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    reasonFetchOptions (search, loading) {
      if (search.length > 0) {
        let _this = this
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(() => {
          loading(true)

          let filters = {
            'searchTerm': search,
            'includeAlan': false
          }

          ValuationReasonService.getReasonOptions(filters).then(res => {
            _this.reasonList = res.data.data
            loading(false)
          })
        }, this.delay)
      }
    },
    /**
     * Lista de origens do elogio/reclamação.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    originFetchOptions (search, loading) {
      if (search.length > 0) {
        let _this = this
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(() => {
          loading(true)

          let filters = {
            'searchTerm': search,
            'includeAlan': false
          }

          PersonService.getPersons(filters).then(res => {
            _this.originList = res.data.data
            loading(false)
          })
        }, this.delay)
      }
    },
    /**
     * Lista de destinos para o elogio/reclamação.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    employeeFetchOptions (search, loading) {
      if (search.length > 0) {
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(() => {
          loading(true)

          let filters = {
            'searchTerm': search,
            'includeAlan': true
          }

          EmployeeService.getEmployeeOptions(filters).then(res => {
            let records = res.data.data
            this.employeeList = records.map((record) => {
              let equipes = record.equipes.map(x => {
                return {
                  id: x.equipe.id,
                  nome: x.equipe.nome
                }
              })

              let selected = {
                id: null,
                nome: 'Sem opções'
              }

              if (equipes.length > 0) {
                selected = equipes[0]
              }

              return {
                id: record.id,
                id_employee: record.id_employee,
                nome: record.id_pessoa.nome,
                protagonista: 1,
                equipe: {
                  selected: selected,
                  options: equipes
                },
                status: 1
              }
            })
            loading(false)
          })
        }, this.delay)
      }
    },
    employeeRemoved (e) {
      // console.log(e)
    },
    employeeSelected (e) {
      // console.log(e)
    },
    toggleEmployeeStatus (val) {
      let employee = {
        id: val.id,
        id_table_origin: val.id_table_origin,
        customer: val.cliente_id
      }

      if (val.status === 1) {
        if (!this.isIncluded(employee)) {
          this.task.contacts.push(employee)
        }
      } else {
        if (this.isIncluded(employee)) {
          let index = this.avaliacao.destinos.findIndex(function (element) {
            return element.id === employee.id
          })
          // remove element from selected options
          this.avaliacao.destinos.splice(index, 1)
        }
      }
    },
    onAllow () {
      // console.log('onAllow: ', this.valuation)
      this.submitted = true
      this.$validator.validateAll('frmModeration').then((valid) => {
        if (valid) {
          let formData = this.makeFormData()
          this.isLoading = true
          ValuationService.postAllow(formData).then(dataJson => {
            this.isLoading = false
            // console.log('dataJson: ', dataJson)
            this.$emit('refresh')
          }).then(function (res) {
            let msg = `<h4>Sua moderação foi registrada com sucesso!</h4>`
            global.instanceApp.$alertSwal.modalAlert(null, msg, 'success', '').then((result) => {})
            this.isLoading = false
            this.isPageLoading = false
          }).catch(err => {
            if (err.code === 400) {
              this.displayErrors(err.errors)
            }
            this.isLoading = false
          })
        }
      })
    },
    onRefuse () {
      this.submitted = true
      this.$validator.validateAll('frmModeration').then((valid) => {
        if (valid) {
          let formData = this.makeFormData()
          this.isLoading = true
          ValuationService.postRefuse(formData).then(dataJson => {
            this.isLoading = false
            this.$emit('refresh')
          }).then(function (res) {
            let msg = `<h4>Sua moderação foi registrada com sucesso!</h4>`
            global.instanceApp.$alertSwal.modalAlert(null, msg, 'success', '').then((result) => {})
            this.isLoading = false
            this.isPageLoading = false
          }).catch(err => {
            if (err.code === 400) {
              this.displayErrors(err.errors)
            }
            this.isLoading = false
          })
        }
      })
    },
    makeFormData () {
      let formData = new FormData()

      formData.append('id', this.valuation.id)
      formData.append('motivo', this.valuation.motivo.id)
      if (this.valuation.referencia_interna) {
        formData.append('referenciaInterna', this.valuation.referencia_interna)
      }
      formData.append('texto', this.valuation.texto)

      let alvos = this.valuation.alvos.map(x => {
        return {
          id: x.id,
          protagonista: x.protagonista,
          equipe: x.equipe.selected ? x.equipe.selected.id : null
        }
      })
      formData.append('alvos', JSON.stringify(alvos))

      formData.append('origem', this.valuation.origem.id)
      formData.append('peso', this.valuation.peso)
      formData.append('valor', this.valuation.valor)
      formData.append('novosArquivos', JSON.stringify(this.newFiles))
      formData.append('arquivosExcluidos', JSON.stringify(this.deletedFiles))

      if (this.valuation.observacao) {
        formData.append('observacao', this.valuation.observacao)
      }

      return formData
    },
    onSubmit (form) {
      // console.log(form)
    },
    onDownload (file) {
      this.isLoading = true
      ValuationService.getFile(file.id).then(res => {
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', file.original_name)
        global.document.body.appendChild(link)
        link.click()
      }).finally(() => {
        this.isLoading = false
      })
    },
    onDeleteFile (file) {
      this.isLoading = true
      this.deletedFiles.push(file.id)
      let index = this.valuation.arquivos.findIndex((x) => {
        return x.id === file.id
      })
      this.valuation.arquivos.splice(index, 1)
      this.isLoading = false

      // ValuationService.deleteFile(this.valuation.id, file.id).then(res => {
      //   if (res.httpCode === 200) {
      //     console.log(res.data)
      //     let index = this.valuation.arquivos.findIndex((x) => {
      //       return x.id === file.id
      //     })
      //     this.valuation.arquivos.splice(index, 1)
      //     this.isLoading = false
      //   }
      // }).finally(() => {
      //   this.isLoading = false
      // })
    }
  },
  computed: {
    isSuperAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isTeamAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_VALUATION_MANAGER') !== -1) {
        return true
      }
      return false
    },
    typeName () {
      let name = 'Avaliação'

      if (!this.valuation.motivo) {
        return name
      }

      if (this.valuation.motivo.tipo.nome) {
        return this.valuation.motivo.tipo.nome
      } else if (this.valuation.motivo.tipo) {
        return this.valuation.motivo.tipo
      }

      return name
    }
  }
}
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 15px;
  right: 33px;
}

tr.internal {
  background-color: #fcf8e3;
}

.royalc-btn-close {
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #84754E;
  background: transparent;
}

.btn-a-color {
  color: #534B41 !important;
}

@media (min-width: 768px) {
    .min-width-card {
        min-width: 640px;
    }
}

@media (min-width: 992px) {
    .min-width-card {
        min-width: 860px;
    }
}
</style>
